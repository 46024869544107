.startProgressbar {
    width: 20rem;
    transition: width 5000ms;
}

.resetProgressbar {
    width: 0;
    transition: width 0ms;
}

#slide-progress {
    background-color: darkgrey;
    position: absolute;
    top: 16em;
    left: 60em;
    /*width: 0;*/
    height: 5px;
    border-radius: 10px;
}

/*Circular Progress Bar */
.circular {
    height: 100px;
    width: 100px;
    position: relative;
    transform: scale(2);
}

.circular .inner {
    position: absolute;
    z-index: 6;
    top: 50%;
    left: 50%;
    height: 80px;
    width: 80px;
    margin: -40px 0 0 -40px;
    background: rgba(0, 0, 0, 0.9);
    /*background: #dde6f0;*/
    border-radius: 100%;

}

.circular .number {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    font-size: 18px;
    font-weight: 500;
    color: #4158d0;
}

.circular .bar {
    position: absolute;
    height: 100%;
    width: 100%;
    background: #fff;
    -webkit-border-radius: 100%;
    clip: rect(0px, 100px, 100px, 50px);
}

.circle .bar .progress {
    position: absolute;
    height: 100%;
    width: 100%;
    -webkit-border-radius: 100%;
    clip: rect(0px, 50px, 100px, 0px);
    background: #4158d0;
}

.startProgress {
    width: 100%;
    transition: width 5000ms;
}

.resetProgress {
    width: 0;
    clip: rect(0px, 0, 0px, 0px);
    transition: width 0ms;
}

.circle .left .progress {
    z-index: 1;
    animation: left 4s linear both;
}

@keyframes left {
    100% {
        transform: rotate(180deg);
    }
}

.circle .right {
    transform: rotate(180deg);
    z-index: 3;

}

.circle .right .progress {
    animation: right 4s linear both;
    animation-delay: 4s;
}

@keyframes right {
    100% {
        transform: rotate(180deg);
    }
}


/*!* Medium devices (tablets, less than 62em)*!*/
/*@media (min-width: 992px) {*/
/*    #slide-progress {*/
/*        background-color: darkgrey;*/
/*        position: absolute;*/
/*        top: 16em;*/
/*        left: 60em;*/
/*        !*width: 0;*!*/
/*        height: 5px;*/
/*        border-radius: 10px;*/
/*    }*/
/*}*/

/*!* Large devices (desktops, less than 75em) *!*/
/*@media (min-width: 1200px) {*/
/*    #slide-progress {*/
/*        background-color: darkgrey;*/
/*        position: absolute;*/
/*        top: 16em;*/
/*        left: 60em;*/
/*        !*width: 0;*!*/
/*        height: 5px;*/
/*        border-radius: 10px;*/
/*    }*/
/*}*/

/*!* Extra small devices (portrait phones, less than 34em) *!*/
/*@media (max-width: 33.9em) {*/
/*    #slide-progress {*/
/*        display: none;*/
/*    }*/
/*}*/

/*@media (max-width: 767px) {*/
/*    #slide-progress {*/
/*        !*width: 0;*!*/
/*        background-color: #a9a9a9;*/
/*        position: absolute;*/
/*        top: 52em;*/
/*        left: 23em;*/
/*        height: 5px;*/
/*        border-radius: 10px;*/
/*    }*/
/*}*/

/*!* Medium devices (tablets, less than 62em)*!*/
/*@media (max-width: 991px) {*/
/*    #slide-progress {*/
/*        background-color: darkgrey;*/
/*        position: absolute;*/
/*        top: 16em;*/
/*        left: 33em;*/
/*        !*width: 0;*!*/
/*        height: 5px;*/
/*        border-radius: 10px;*/
/*    }*/
/*}*/

/*@media (min-width: 992px) and (max-width: 1199px) {*/
/*    #slide-progress {*/
/*        background-color: darkgrey;*/
/*        position: absolute;*/
/*        top: 18em;*/
/*        left: 40em;*/
/*        !*width: 0;*!*/
/*        height: 5px;*/
/*        border-radius: 10px;*/
/*    }*/
/*}*/

